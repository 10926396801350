import { Component, ElementRef, HostBinding, HostListener, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ButtonComponent } from '@progress/kendo-angular-buttons/button/button.component';
import { PositionMode } from '@progress/kendo-angular-popup';

@Component({
  selector: 'kendo-popup-field',
  template: `
    <button
      type="button"
      class="popup--trigger"
      kendoButton
      #anchor="kendoButton"
      fillMode="clear"
      (click)="onTogglePopup()"
    >
      <i *ngIf="props.icon" [classList]="props.icon"></i>
      <span *ngIf="props.text" [ngClass]="props.textClass">{{ props.text }}</span>
    </button>
    <kendo-popup
      #popup
      [anchor]="popupAnchor.element"
      [positionMode]="positionMode"
      [popupAlign]="props.popupAlign"
      [anchorAlign]="props.anchorAlign"
      [animate]="props.animate"
      [margin]="props.margin"
      (anchorViewportLeave)="show = false"
      *ngIf="show"
    >
      <div class="popup">
        <div [ngClass]="{ 'popup--scroll': props.scrollable }" class="popup__content">
          <div [ngClass]="props.contentClass" [safeHTML]="props.content"></div>
        </div>
      </div>
    </kendo-popup>
  `,
  styles: [
    `
      :host {
        --popup-padding: 0.5rem;
        --popup-margin: 0px;
        --popup__content-padding: 1rem;
      }

      .popup {
        display: flex;
        flex-direction: column;
        padding: var(--popup-padding);

        &--trigger {
          padding: 0;
          color: var(--primary);

          i {
            margin-right: 4px;
          }
        }

        &--scroll {
          padding: 0.5rem;
          height: auto;
          max-height: 250px;
          overflow: auto;
        }

        &__content {
          /* The offset is calculated using all values of parents that somehow increase the distance to the left.
             The offset reduces the width of the element to mirror the space on the left to the right. */
          --max-width-offset: calc(
            2 * (var(--popup-padding) + var(--popup__content-padding) + var(--container-padding) + var(--popup-margin))
          );
          max-width: min(400px, calc(100vw - var(--max-width-offset)));
          padding: var(--popup__content-padding);
          font-size: 14px;
        }
      }

      kendo-popup {
        @media only screen and (max-width: 576px) {
          max-width: 100vw;
        }

        @media only screen and (min-width: 576px) {
          max-width: 75vw;
        }

        @media only screen and (min-width: 768px) {
          max-width: 60vw;
        }

        @media only screen and (min-width: 992px) {
          max-width: 50vw;
        }

        @media only screen and (min-width: 1200px) {
          max-width: 33vw;
        }

        @media only screen and (min-width: 1600px) {
          max-width: 22vw;
        }
      }

      /* EXPERIMENTAL START */
      ::ng-deep body.experimental-layout kendo-popup {
        max-width: min(100vw, 576px);
      }
      /* EXPERIMENTAL END */
    `,
  ],
})
export class KendoPopupField extends FieldType<FieldTypeConfig> {
  @ViewChild('anchor', { static: false })
  public popupAnchor!: ButtonComponent;
  @ViewChild('popup', { read: ElementRef })
  public popupElement!: ElementRef<any>;
  public show = false;

  // Position 'absolute' prevents page scrolling to the top for mobile
  public readonly positionMode: PositionMode = 'absolute';

  override defaultOptions = {
    props: {
      animate: {
        type: 'zoom',
        duration: 200,
        direction: 'right',
      },
      popupAlign: {
        vertical: 'top',
        horizontal: 'left',
      },
      anchorAlign: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      margin: {
        horizontal: 0,
        vertical: 30,
      },
    },
  };

  @HostBinding('style')
  get hostStyle() {
    return `--popup-margin: ${this.props.margin?.horizontal ?? 0}px;`;
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.containsElement(event.target)) {
      this.hidePopup();
    }
  }

  @HostListener('keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.hidePopup();
    }
  }

  public onTogglePopup(): void {
    this.show = !this.show;
  }

  private hidePopup() {
    this.show = false;
  }

  private containsElement(target: any): boolean {
    return (
      this.popupAnchor.element.contains(target) ||
      (this.popupElement ? this.popupElement.nativeElement.contains(target) : false)
    );
  }
}
