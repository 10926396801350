import { AbstractControl, ValidationErrors } from '@angular/forms';

class RtpOnboardingIbanValidator {
  // For reference see https://geteazi.atlassian.net/browse/EAC-2242
  private static BLZ_WHITELIST = [
    // BLZ         BIC            Name
    '50190000', // FFVBDEFF XXX   Frankfurter Volksbank
    '40361906', // GENODEM1IBB    Volksbank im Münsterland eG
    '42861387', // GENODEM1BOB    VR Bank Westmünsterland eG
    '70093200', // GENODEF1STH    Volksbank Raiffeisenbank Starnberg
    '24060300', // GENODEF1NBU    Volksbank Lüneburger Heide eG
    '24061392', // GENODEF1DAB    Volksbank Lüneburger Heide eG
    '24090041', // GENODEF1LUE    Volksbank Lüneburger Heide eG
    '25891636', // GENODEF1SOL    Volksbank Lüneburger Heide eG
    '61191310', // GENODES1VBP    Volksbank Plochingen eG
    '26991066', // GENODEF1WOB    Volksbank BRAWO eG
    '70169351', // GENODEF1ELB    Raiffeisenbank Lechrain eG
    '68064222', // GENODE61GUN    Raiffeisenbank im Breisgau eG
    '75069038', // GENODEF1FKS    Raiffeisenbank Falkenstein-Wörth eG
    '73369859', // GENODEF1BIN    Raiffeisenbank Bidingen eG
    '74069744', // GENODEF1GRT    Raiffeisenbank Grainet eG
    '76069576', // GENODEF1BPL    Raiffeisenbank Plankstetten AG
    '76069462', // GENODEF1GDG    Raiffeisenbank Altmühl-Jura eG
    '72062152', // GENODEF1MTG    VR-Bank Handels- und Gewerbebank eG
    '60069066', // GENODES1RBA    Raiffeisenbank Niedere Alb eG
    '72069235', // GENODEF1THS    Raiffeisenbank Thannhausen eG
    '70169524', // GENODEF1RME    Bankhaus RSA eG
    '51390000', // VBMHDE5FXXX    Volksbank Mittelhessen eG
    '50190000', // FFVBDEFFXXX    Frankfurter Volksbank Rhein-Main eG
    '44761534', // GENODEM1NRD    Volksbank in Südwestfalen eG
    '37069405', // GENODED1KAA    Raiffeisenbank Kaarst eG
    '52360059', // GENODEF1KBW    Waldeck-Frankenberger Bank eG
    '70169310', // GENODEF1ALX    Alxing-Brucker Genossenschaftsbank eG
    '73369954', // GENODEF1WGO    Raiffeisenbank Wald-Görisried eG
    '32061414', // GENODED1KMP    Volksbank Kempen-Grefrath eG
    '21464671', // GENODEF1TOB    Raiffeisenbank eG, Todenbüttel
    '62062643', // GENODES1VFT    Volksbank Flein-Talheim eG
    '45260475', // GENODEM1BFG    Spar- und Kreditbank des Bundes Freier evangelischer Gemeinden eG
    '38260082', // GENODED1EVB    Volksbank Euskirchen eG
    '60069544', // GENODES1RWN    Raiffeisenbank Westhausen eG
    '37069252', // GENODED1ERE    Volksbank Erft eG
    '72069036', // GENODEF1BOI    Raiffeisenbank Bobingen eG
    '87069077', // GENODEF1BST    Vereinigte Raiffeisenbank Burgstädt eG
    '70169165', // GENODEF1SBC    Raiffeisenbank Chiemgau-Nord-Obing eG
    '72160818', // GENODEF1INP    Volksbank Raiffeisenbank Bayern Mitte eG
    '62061991', // GENODESVOS     Volksbank Sulmtal eG
    '70169575', // GENODEF1TRH    Raiffeisenbank Türkheim eG
    '83094454', // GENODEF1RUJ    Volksbank eG Gera-Jena-Rudolstadt
    '28563749', // GENODEF1MML    Raiffeisenbank eG, Moormerland
    '73369871', // GENODEF1EGB    Raiffeisenbank Baisweil-Eggenthal-Friesenried eG
    '77069906', // GENODEF1WSZ    Raiffeisenbank Wüstenselbitz eG
    '51861616', // GENODE51REW    Landbank Horlofftal eG
    '51961801', // GENODE51FEL    Volksbank Feldatal eG
    '60069527', // GENODES1RNS    Volksbank Brenztal eG
    '60069595', // GENODES1SBB    Raiffeisenbank Schronzberg-Rot am See eG
    '70169509', // GENODEF1PEI    Raiffeisenbank Pfaffenwinkel eG
    '24162898', // GENODEF1LAS    Spar- und Darlehnskasse Börde Lamstedt-Hechthausen eG
    '77069044', // GENODEF1KC2    Raiffeisenbank Küps-Mitwitz-Stockheim eG
    '74369146', // GENODEF1RZK    Raiffeisenbank Rattiszell-Konzell eG
    '59393000', // GENODE51LEB    LevoBank eG
  ];
  private static IBAN_BLZ_RANGE = { start: 4, end: 12 };
  private blz: string;

  constructor(iban: string) {
    this.blz = RtpOnboardingIbanValidator.extractBlz(iban);
  }

  public isBlzAllowed(): boolean {
    return RtpOnboardingIbanValidator.BLZ_WHITELIST.includes(this.blz);
  }
  private static extractBlz(iban: string): string {
    return iban.substring(this.IBAN_BLZ_RANGE.start, this.IBAN_BLZ_RANGE.end);
  }
}
export function ibanBlzWhitelistValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return null;
  }
  const iban = control.value as string;
  const ibanValidator = new RtpOnboardingIbanValidator(iban);
  return ibanValidator.isBlzAllowed() ? null : { ibanBlzForbidden: true };
}
