// Source: https://github.com/ngx-formly/ngx-formly/blob/main/src/ui/kendo/radio/src/radio.type.ts

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kendo-radio-field',
  template: `
    <div class="radio-list">
      <ng-container *ngFor="let option of props.options | formlySelectOptions: field | async; let i = index">
        <div class="radio-item">
          <input
            type="radio"
            kendoRadioButton
            [id]="id + '_' + i"
            [name]="field.name || id"
            [value]="option.value"
            [formControl]="formControl"
            [formlyAttributes]="field"
            [attr.disabled]="option.disabled || formControl.disabled ? true : null"
            [attr.data-cy]="props['data-cy'] ?? 'kendo-radio-field-' + i"
          />
          <label class="k-radio-label" [for]="id + '_' + i">
            {{ option.label }}
          </label>
        </div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .radio-list {
        display: flex;
        gap: 8px;

        @media (max-width: 500px) {
          flex-direction: column;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoRadioField extends FieldType<FieldTypeConfig> {
  override defaultOptions = {
    props: { options: [] },
  };
}
