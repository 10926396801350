import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

// regex from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
// matches additional requirements of R+V (see https://geteazi.atlassian.net/browse/EAC-2052)
export const RUV_EMAIL_REGEX = /^.+@.+\..+$/;

export function emailValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  const emailAddress = control.value as string;
  if (!emailAddress) {
    // empty or undefined value is interpreted as valid; see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#value
    return null;
  }

  if (emailAddress.length > 50) {
    return { emailMaxLength: true };
  }

  const matchesEmailFormat = EMAIL_REGEX.test(emailAddress);
  const matchesRuvRequirements = RUV_EMAIL_REGEX.test(emailAddress);
  const isValid = matchesEmailFormat && matchesRuvRequirements;

  return isValid ? null : { email: true };
}
