export interface Case {
  reference: string;
  externalReference?: string;
  journeyId: string;
  journeyName?: CaseType;
  verificationCode: string;
  frontendOnlyFields?: Record<string, any>;
}

export interface Customer {
  salutation: string;
  title?: string;
  firstName: string;
  lastName: string;
  readonly birthDate: string; // Will never change. This value is only used to verify the "sendCase"-Request
}

export interface Address {
  street?: string;
  streetNumber?: string;
  postalCode?: string;
  city?: string;
  countryCode?: string;
  additionalAddressInfo?: string;
}

export interface PdfLink {
  url: string;
  expiresAt: string;
}

export enum CaseType {
  AFTERSALES = 'VH-Aftersales',
  IBAN = 'KH-IBAN',
  KONTEXT = 'KontextInsert',
  PRL = 'PRL.2.0',
  GENAI = 'genai',
  SWITCHER = 'Switcher-Brief',
  TESTALLDATA = 'test-all-data',
  VISUALVEST = 'visualvest',
  RTP = 'RTP_ONBOARDING',
  KKINK = 'kkink',
  SWITCHERGEWINN = 'SwitcherGewinn',
  NDOKDEW = 'NDOKDEW',
  ANHAENGER = 'Anhaenger',
  VERIFIZIERUNGSMAIL = 'Verifizierungsmail',
  GYM = 'gym',
  FAST_RESPONSE = 'fast-response',
  POST_DIGITAL = 'post-digital',
  KH_WALLET = 'kh-wallet',
  VERIFY = 'kontextverify',
}
