<ng-container *ngIf="organization$ | async as organization">
  <kendo-appbar
    class="footer"
    position="bottom"
    positionMode="fixed"
    themeColor="inherit"
    [ngClass]="{ 'footer-with-border': organization.footerHasDivider }"
  >
    <div *ngIf="organization.dontShowContactPanel !== true" class="info">
      <div class="contact">
        <button
          #anchor
          type="button"
          class="contact__btn k-button k-button-md k-rounded-md k-button-solid-base k-button-solid"
          (click)="onOpenContactPopup($any(anchor))"
          data-cy="contact-button"
        >
          <i class="ruv-icon ruv-icon-medium ruv-arrow-right"></i>
          <img alt="Company Logo" class="logo" decoding="sync" [src]="organization.logoUrl" />

          <div class="contact__btn--text">
            <header>
              {{ 'FOOTER.INFO.CONTACT.BUTTON.HEADER' | translate }}
            </header>
            <strong>
              {{ 'FOOTER.INFO.CONTACT.BUTTON.TEXT' | translate }}
            </strong>
          </div>
        </button>
      </div>
    </div>

    <div class="navigation">
      <div
        *ngIf="!!organization.footerText"
        class="footer-text"
        [safeHTML]="organization.footerText | parsePlaceholders: organization"
        data-cy="footer-text"
      ></div>

      <img
        alt="Footer Logo"
        *ngIf="organization.footerLogoUrl !== ''; else noLogo"
        decoding="sync"
        [src]="organization.footerLogoUrl"
        data-cy="footer-logo"
      />

      <ng-template #noLogo>
        <div class="blank"></div>
      </ng-template>

      <nav>
        <a
          [href]="organization.dataPrivacyUrl"
          [target]="'_blank'"
          rel="noopener noreferrer"
          (click)="onClickHyperlink('Datenschutz')"
          data-cy="data-security-link"
        >
          {{ 'FOOTER.NAVIGATION.PRIVACY' | translate }}
        </a>
        <a
          [href]="organization.imprintUrl"
          [target]="'_blank'"
          rel="noopener noreferrer"
          (click)="onClickHyperlink('Impressum')"
          data-cy="imprint-link"
        >
          {{ 'FOOTER.NAVIGATION.IMPRINT' | translate }}
        </a>
      </nav>
    </div>
  </kendo-appbar>
</ng-container>
