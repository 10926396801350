import { Injectable } from '@angular/core';
import { IbanCase } from './iban-case.model';
import { StringUtil } from '@shared/string-util';
import { CaseHelper } from '../case-helper';

@Injectable()
export class IbanCaseHelper extends CaseHelper<IbanCase> {
  public override preProcessCase(caseObject: IbanCase): IbanCase {
    // Set default country code to DE if empty
    if (caseObject.address) {
      caseObject.address = {
        ...caseObject.address,
        countryCode: StringUtil.isEmpty(caseObject.address?.countryCode) ? 'DE' : caseObject?.address?.countryCode,
      };
    }

    return caseObject;
  }
}
