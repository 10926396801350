import { registerLocaleData } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

@Component({
  selector: 'ruv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @HostBinding('class.footer-position-unset')
  public footerPositionIsUnset: boolean = false;

  constructor() {
    this.manageFooterPositionOnMobile();
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  }

  private manageFooterPositionOnMobile() {
    /* When on Mobile, the screen height gets reduced when the user opens the keyboard.
       If there is not enough space, the footer gets pushed over the rest of the content and obscures
       the elements. This makes it difficult to select other inputs and looks ugly.
       Therefore the position of the footer needs to changed while the keyboard is open and reset when closed. */
    const isMobile = window.matchMedia('only screen and (max-width: 768px)').matches;

    if (isMobile) {
      window.addEventListener('focusin', (event: FocusEvent) => {
        /* The keyboard only opens when the user clicks into an input or textarea. */
        const targetName = (event.target as HTMLElement).tagName.toLowerCase();
        if (targetName === 'input' || targetName === 'textarea') {
          this.footerPositionIsUnset = true;
        }
      });

      window.addEventListener('focusout', (event: FocusEvent) => {
        this.footerPositionIsUnset = false;
      });
    }
  }
}
