import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { ParsePaceholdersPipe } from '@shared/pipes/parse-placeholders.pipe';

/**
 * props.text => the text that will be parsed
 * props.model => the object containing the values that will replace the placeholders
 */
@Component({
  selector: 'parse-placeholder-wrapper',
  template: ` <ng-container #fieldComponent></ng-container> `,
  styles: [],
})
export class ParsePlaceholdersWrapper extends FieldWrapper implements OnInit {
  constructor(private parsePlaceholder: ParsePaceholdersPipe) {
    super();
  }

  ngOnInit(): void {
    if (this.field.props) {
      this.field.props.text = this.parsePlaceholder.transform(this.field.props?.text, this.field.props?.model);
    }
  }
}
