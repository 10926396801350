import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { PresetOption } from '@ngx-formly/core/lib/models';

@Injectable()
export class RtpAccordionPresetProvider {
  constructor() {}
  getConfiguration(): FormlyFieldConfig {
    return {
      key: 'rtp-accordion',
      type: 'accordion',
      props: {
        label: 'SUCCESS.RTP_ONBOARDING.FAQ.TITLE',
        labelClass: 'col-12 title no-padding--top',
        itemTitleClass: 'text text--bold',
        itemTextClass: 'text',
        translate: true,
        translateItems: true,
      },
    };
  }
}

export function registerRtpAccordionPreset(): PresetOption {
  return {
    name: 'rtp-accordion',
    config: new RtpAccordionPresetProvider(),
  };
}
