import { ExperimentId, SwitcherCase } from './switcher-case.model';
import { DefaultCaseHelper } from '../default/default-case-helper';

export class SwitcherCaseHelper extends DefaultCaseHelper<SwitcherCase> {
  public override preProcessCase(caseObject: SwitcherCase): SwitcherCase {
    return super.preProcessCase(caseObject);
  }
  public override postProcessCase(caseObject: SwitcherCase, initialCase: SwitcherCase): SwitcherCase {
    return super.postProcessCase(caseObject, initialCase);
  }

  useAlternateTextFn = (formState: any, model: SwitcherCase) => {
    return model.experimentId === ExperimentId.WALLET;
  };
}
