import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { MaskedTextBoxComponent } from '@progress/kendo-angular-inputs';
@Component({
  selector: 'kendo-masked-textbox-field',
  template: `
    <kendo-maskedtextbox
      [class.k-state-invalid]="showError"
      [prompt]="to.prompt"
      [readonly]="to.readonly ?? false"
      [mask]="to.mask"
      [maskOnFocus]="to.maskValidation"
      [maskValidation]="to.maskValidation"
      [includeLiterals]="to.includeLiterals"
      [formControl]="props.disabled ? disabledControl : formControl"
      [formlyAttributes]="field"
      [attr.data-cy]="props['data-cy'] ?? 'kendo-masked-textbox-field'"
    >
    </kendo-maskedtextbox>
  `,
})
export class KendoMaskedTextBoxField extends FieldType<FieldTypeConfig> implements AfterViewInit {
  @ViewChild(MaskedTextBoxComponent) textBoxRef!: MaskedTextBoxComponent;
  ngAfterViewInit() {
    this.disableAutoComplete();
    this.setPlaceholder(this.props.placeholder ?? '');
  }
  get disabledControl() {
    return new FormControl({ value: this.formControl.value, disabled: true });
  }
  override defaultOptions = {
    props: {
      prompt: '_',
      maskOnFocus: false,
      maskValidation: true,
      includeLiterals: false,
    },
  };
  private disableAutoComplete() {
    // autocomplete=off is bugged in chrome, suggestion is to fill with non-conform value
    // see: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
    const elementRef = this.textBoxRef.input as ElementRef<HTMLInputElement>;
    elementRef.nativeElement.setAttribute('autocomplete', 'disable-autocomplete');
  }
  private setPlaceholder(placeholder: string) {
    const elementRef = this.textBoxRef.input as ElementRef<HTMLInputElement>;
    elementRef.nativeElement.setAttribute('placeholder', placeholder);
  }
}
