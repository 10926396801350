import { KKinkCase, KKinkOption, KKinkPaymentCycle } from './kkink-case.model';
import { DefaultCaseHelper } from '../default/default-case-helper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import dayjs from 'dayjs';

export const KKINK_IDS = ['110bd', 'f7da9'];

export class KKinkCaseHelper extends DefaultCaseHelper<KKinkCase> {
  public override preProcessCase(caseObject: KKinkCase): KKinkCase {
    return {
      ...caseObject,
      selectedOptionId: caseObject.currentOption.id,
      options: caseObject.options.sort((x, y) => Number(x.isSwitchOption) - Number(y.isSwitchOption)),
      frontendOnlyFields: {},
    };
  }

  public override postProcessCase(caseObject: KKinkCase): KKinkCase {
    return caseObject;
  }

  public getCurrentOptionPrice(formState: any, model: KKinkCase, field?: FormlyFieldConfig): number {
    const { initialModel }: { initialModel: KKinkCase } = formState;
    const price = this.getOptionPrice(initialModel.currentOption, initialModel.workshopServiceOptionActive);

    return price;
  }

  public calcCurrentSum(formState: any, model: KKinkCase, field?: FormlyFieldConfig): number {
    const { initialModel }: { initialModel: KKinkCase } = formState;
    const sum = this.calcSum(initialModel, initialModel.currentOption);

    return sum;
  }

  public areAdditionalOptionsShown = false;

  public showAdditionalOptions(field: FormlyFieldConfig) {
    this.areAdditionalOptionsShown = true;
    field.options?.checkExpressions!(field); // Trigger rerunning expressions (I can't find how this works exactly in the documentation. But it seems to trigger checkExpressions on other fields as well, not only the passed field)
  }

  public getSelectedOptionPrice(formState: any, model: KKinkCase, field?: FormlyFieldConfig): number {
    const selectedOption = this.getSelectedOption(model);
    const price = this.getOptionPrice(selectedOption, model.workshopServiceOptionActive);

    return price;
  }

  public showSwitchOptionInfo(model: KKinkCase): boolean {
    if (model.recommendedOption.isSwitchOption) return true;

    if (this.areAdditionalOptionsShown) {
      return model.options.some((option) => option.isSwitchOption);
    }

    return false;
  }

  public getOptionLabelText(formState: any, model: KKinkCase): string {
    let translationKey;
    if (model.recommendedOption.isSwitchOption || model.options.some((option) => option.isSwitchOption)) {
      translationKey = 'FORM.KKINK.OPTIONS.LABEL_SWITCHOPTION';
    } else {
      translationKey = 'FORM.KKINK.OPTIONS.LABEL';
    }

    return formState.translateService.instant(translationKey);
  }

  public calcSelectedSum(formState: any, model: KKinkCase, field?: FormlyFieldConfig): number {
    const selectedOption = this.getSelectedOption(model);
    const sum = this.calcSum(model, selectedOption);

    return sum;
  }

  public calcSavingsPerYear(formState: any, model: KKinkCase, field?: FormlyFieldConfig): number {
    const oldPrice = this.calcCurrentSum(formState, model, field);
    const newPrice = this.calcSelectedSum(formState, model, field);
    let savings = oldPrice - newPrice;

    switch (model.paymentCycle) {
      case KKinkPaymentCycle.MONTHLY:
        savings *= 12;
        break;
      case KKinkPaymentCycle.QUARTERLY:
        savings *= 4;
        break;
      case KKinkPaymentCycle.BIANNUALLY:
        savings *= 2;
        break;
      case KKinkPaymentCycle.YEARLY:
        break;
    }

    return savings;
  }

  public getDateText(formState: any, model: KKinkCase, field?: FormlyFieldConfig): string {
    if (!(field?.props?.text ?? '').startsWith('FORM.')) {
      return field?.props?.text ?? '';
    }

    return formState.translateService.instant(field?.props?.text ?? '', {
      date: dayjs(model.datasetLastAccessedAt).format('DD.MM.YYYY'),
    });
  }

  public getIntervallText(formState: any, model: KKinkCase, field?: FormlyFieldConfig): string {
    let intervall = '';

    switch (model.paymentCycle) {
      case KKinkPaymentCycle.MONTHLY:
        intervall = 'FORM.INTERVAL.MONTHLY';
        break;
      case KKinkPaymentCycle.QUARTERLY:
        intervall = 'FORM.INTERVAL.QUARTERLY';
        break;
      case KKinkPaymentCycle.BIANNUALLY:
        intervall = 'FORM.INTERVAL.BIANNUALLY';
        break;
      case KKinkPaymentCycle.YEARLY:
        intervall = 'FORM.INTERVAL.YEARLY';
    }

    if (!(field?.props?.text ?? '').startsWith('FORM.')) {
      return field?.props?.text ?? '';
    }

    return formState.translateService.instant(field?.props?.text ?? '', {
      intervall: formState.translateService.instant(intervall),
    });
  }

  public getSelectedOptionText(formState: any, model: KKinkCase, field?: FormlyFieldConfig): string {
    const { initialModel }: { initialModel: KKinkCase } = formState;
    const selectedOption = this.getSelectedOption(model);

    const text = this.getOptionText(
      selectedOption,
      model.workshopServiceOptionActive,
      initialModel.workshopServiceOptionActive,
    );

    return text;
  }

  private getOptionText(
    option: KKinkOption,
    workshopServiceOptionActive: boolean,
    initialWorkshopServiceOptionActive: boolean,
  ): string {
    let text = option.description ?? '';

    // only print "+ Werkstattservice" if workshopServiceOptionActive was initially false and was selected by user
    if (!initialWorkshopServiceOptionActive && workshopServiceOptionActive) {
      text += `<br>+ Werkstattservice`;
    }

    return text;
  }

  public hasContractChanged(formState: any, model: KKinkCase): boolean {
    const { initialModel }: { initialModel: KKinkCase } = formState;

    const hasWorkshopServiceChanged = initialModel.workshopServiceOptionActive !== model.workshopServiceOptionActive;

    const hasOptionChanged = initialModel.currentOption.id !== model.selectedOptionId;

    const contractChanged = hasWorkshopServiceChanged || hasOptionChanged;

    return contractChanged;
  }

  private getSelectedOption(model: KKinkCase): KKinkOption {
    const allOptions = model.options.concat(model.recommendedOption, model.currentOption);
    const selectedOption = allOptions.find((option) => option.id === model.selectedOptionId);
    if (!selectedOption) throw Error('SelectedOptionId not found in the options');

    return selectedOption;
  }

  private calcSum(model: KKinkCase, selectedOption: KKinkOption) {
    const optionPrice = this.getOptionPrice(selectedOption, model.workshopServiceOptionActive);
    const sum = optionPrice + model.basePrice;
    return sum;
  }

  private getOptionPrice(option: KKinkOption, workshopServiceActive: boolean) {
    const optionPrice = workshopServiceActive ? option.workshopPrice : option.price;

    return optionPrice;
  }
}
