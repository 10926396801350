// Source: https://github.com/ngx-formly/ngx-formly/blob/main/src/ui/kendo/radio/src/radio.type.ts

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kendo-kkink-option-field',
  template: `
    <div class="radio-list">
      <div class="current-option">
        <div class="header text text--bold">
          {{ 'FORM.KKINK.OPTIONS.CURRENT' | translate }}
        </div>
        <div class="radio-item">
          <input
            type="radio"
            kendoRadioButton
            [id]="id + '_' + 0"
            [name]="field.name || id"
            [value]="props.currentOption.id"
            [formControl]="formControl"
            [formlyAttributes]="field"
          />
          <label class="k-radio-label" [for]="id + '_' + 0">
            {{ props.currentOption.description | translate: { price: props.currentOption.price } }}
          </label>
        </div>
      </div>
      <div class="recommended-option">
        <div class="header text text--bold">
          {{ 'FORM.KKINK.OPTIONS.RECOMMENDED' | translate }}
        </div>
        <div class="radio-item">
          <input
            type="radio"
            kendoRadioButton
            [id]="id + '_' + 0"
            [name]="field.name || id"
            [value]="props.recommendedOption.id"
            [formControl]="formControl"
            [formlyAttributes]="field"
          />
          <label class="k-radio-label" [for]="id + '_' + 0">
            {{
              props.recommendedOption.description
                | translate: { price: props.recommendedOption.price }
            }}
          </label>
        </div>
      </div>

      <div *ngIf="props.areAdditionalOptionsShown" class="alternative-options">
        <div class="header text text--bold">
          {{ 'FORM.KKINK.OPTIONS.ALTERNATIVES' | translate }}
        </div>
        <ng-container *ngFor="let option of props.options | kkinkOptions; let i = index">
          <div class="radio-item">
            <input
              type="radio"
              kendoRadioButton
              [id]="id + '_' + i + 1"
              [name]="field.name || id"
              [value]="option.id"
              [formControl]="formControl"
              [formlyAttributes]="field"
            />
            <label class="k-radio-label" [for]="id + '_' + i + 1">
              {{ option.description | translate: { price: option.price } }}
            </label>
          </div>
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      .radio-list {
        display: flex;
        gap: 8px;
        flex-direction: column;
      }
      .radio-item {
        display: flex;
      }

      .current-option,
      .recommended-option,
      .alternative-options {
        .header {
          padding-left: 28px;
        }
      }

      .radio-item + .radio-item {
        margin-top: 8px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoKKinkOptionField extends FieldType<FieldTypeConfig> {
  override defaultOptions = {
    props: { options: [] },
  };
}
