<div *ngIf="organization$ | async as organization" class="container">
  <div class="logo-container">
    <img class="logo" data-cy="contact-button-logo" decoding="sync" [src]="organization.logoUrl" />
  </div>

  <p class="text text--center text--bold" data-cy="contact-button-contactname">
    {{ 'FOOTER.INFO.CONTACT.LABEL' | translate: { company: organization.contactName } }}
  </p>

  <div class="address" data-cy="contact-button-address">
    <span> {{ organization.name }}</span>
    <span> {{ organization.street }} {{ organization.houseNumber }}</span>
    <span> {{ organization.postalCode }} {{ organization.city }}</span>
  </div>

  <div class="communication">
    <div
      *ngIf="!(organization.contactPhone | isEmpty)"
      class="communication__phone"
      data-cy="contact-button-contactphone"
    >
      <i class="ruv-icon ruv-phone"></i>
      <span class="communication__label">
        {{ 'FOOTER.INFO.CONTACT.DETAILS.PHONE' | translate }}
      </span>
      <a class="communication__value" href="{{ 'tel:' + organization.contactPhone }}">
        {{ organization.contactPhone }}
      </a>
    </div>

    <div
      *ngIf="!(organization.contactEmail | isEmpty)"
      class="communication__mail"
      data-cy="contact-button-contactmail"
    >
      <i class="ruv-icon ruv-mail"></i>
      <span class="communication__label">
        {{ 'FOOTER.INFO.CONTACT.DETAILS.MAIL' | translate }}
      </span>
      <a class="communication__value" href="{{ 'mailto:' + organization.contactEmail }}">
        {{ organization.contactEmail }}
      </a>
    </div>
  </div>
</div>
