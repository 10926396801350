<div class="container">
  <div class="centering">
    <ng-container [ngSwitch]="currentCaseType">
      <ng-container *ngSwitchCase="CaseType.KKINK">
        <div class="title emphasize" data-cy="header-text">
          {{ 'ALREADY_SENT.HEADER.TEXT' | translate }}
        </div>
        <p class="text" data-cy="description-text" safeHTML="{{ 'ALREADY_SENT.DESCRIPTION.KKINK' | translate }}"></p>
      </ng-container>

      <ng-container *ngSwitchDefault="">
        <div class="title emphasize" data-cy="header-text">
          {{ 'ALREADY_SENT.HEADER.TEXT' | translate }}
        </div>
        <p class="text" data-cy="description-text" safeHTML="{{ 'ALREADY_SENT.DESCRIPTION.TEXT' | translate }}"></p>
      </ng-container>
    </ng-container>
  </div>
</div>
