<div class="container">
  <div class="centering">
    <header class="title" data-cy="header-text">
      {{ 'ERROR.HEADER.TEXT' | translate }}
    </header>
    <p class="text" data-cy="description-text">
      {{ 'ERROR.DESCRIPTION.TEXT' | translate }}
    </p>
  </div>
</div>
