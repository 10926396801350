import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CaseType } from '@app/states/case/case.model';
import { FormlyFieldConfig } from '@ngx-formly/core';

export enum FormType {
  UseCase = 'use-case',
  Doi = 'doi',
  Success = 'success',
  Confirmation = 'confirmation',
}

@Injectable({
  providedIn: 'root',
})
export class FormFieldsLoaderService {
  constructor(private http: HttpClient) {}

  public loadFormByName(name: string) {
    const path = `assets/forms/${name}.form.json`;
    return this.http.get<FormlyFieldConfig[]>(path);
  }

  public loadFormById(formType: FormType, caseType: CaseType) {
    const path = `assets/forms/${caseType.toLowerCase()}/${formType}.form.json`;
    return this.http.get<FormlyFieldConfig[]>(path);
  }
}
