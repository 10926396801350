// Source: https://github.com/ngx-formly/ngx-formly/blob/main/src/ui/kendo/checkbox/src/checkbox.type.ts

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kendo-checkbox-field',
  template: `
    <div class="checkbox-container">
      <input
        type="checkbox"
        kendoCheckBox
        [class.k-state-invalid]="showError"
        [formControl]="formControl"
        [formlyAttributes]="field"
        rounded="none"
        [attr.data-cy]="props['data-cy'] ?? 'kendo-checkbox-field'"
      />
      <label [for]="id">
        {{ to.text }}
      </label>
    </div>
  `,
  styles: [
    `
      .checkbox-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem;
        border: 2px solid rgba(0, 0, 0, 0.08);
        border-radius: 5px;
      }

      .checkbox-container:hover {
        border: 2px solid rgba(0, 0, 0, 0.2);
      }

      .k-checkbox {
        border-radius: 4px;

        &:focus,
        &.k-focus {
          box-shadow: 0 0 0 2px rgba(0, 25, 87, 0.3);
          outline: none;
        }
      }

      .k-checkbox:not(:checked):hover {
        background-image: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoCheckboxField extends FieldType<FieldTypeConfig> {}
