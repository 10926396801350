import { Pipe, PipeTransform } from '@angular/core';
import get from 'lodash.get';

@Pipe({ name: 'parsePlaceholders' })
export class ParsePaceholdersPipe implements PipeTransform {
  transform(text: string, model: object) {
    const placeholdersPattern = new RegExp(/{{[\w.]+}}/g);
    const placeholders = text.match(placeholdersPattern);

    placeholders?.forEach((placeholder) => {
      const keyStartIndex = 2;
      const keyEndIndex = -2;
      const key = placeholder.slice(keyStartIndex, keyEndIndex);
      const value = get(model, key) ?? placeholder;
      text = text.replace(placeholder, value);
    });

    return text;
  }
}
