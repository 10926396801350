import { Component, OnInit } from '@angular/core';
import { BackendService } from '@app/backend/backend.service';
import { Experiment } from '@app/states/case/switcher/switcher-case.model';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { EMPTY, Observable } from 'rxjs';

@Component({
  selector: 'kendo-incentive-field',
  template: `
    <ng-container
      *let="{
        experiment: experiment$ | async,
      } as state"
    >
      <div *ngIf="state.experiment && state.experiment.landingPageText !== ''" class="incentive">
        <div class="incentive__content">
          <header
            *ngIf="state.experiment.landingPageHeadline"
            [safeHTML]="state.experiment.landingPageHeadline"
          ></header>
          <div class="incentive__content__body" [attr.data-experiment-id]="state.experiment.id">
            <img class="incentive__content__body--image" decoding="sync" [src]="state.experiment.landingPageImage" />
            <div [safeHTML]="state.experiment.landingPageText"></div>
          </div>
        </div>
        <div *ngIf="false" class="incentive__info">
          <i class="ruv-icon ruv-icon-tiny ruv-icon-green ruv-info"></i>
          <p class="incentive__info--text">
            {{ 'FORM.CONSENT.INFO.' + state.experiment.id + '.TEXT' | translate }}
          </p>
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      @import 'colors.scss';

      .incentive {
        display: flex;
        flex-direction: column;
        gap: 1rem 0;
        container: incentive / inline-size;

        &__content {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: auto;
          padding: 1rem;
          background-color: $sand;

          &__body {
            display: flex;
            gap: 1rem;

            &--image {
              width: auto;
              height: 90px;
            }
          }
        }

        &__info {
          display: flex;
          background-color: $green-1;
          padding: 1rem;
          gap: 8px;
          align-items: center;
          border-radius: 4px;

          &--text {
            font-size: 14px;
            text-align: left;
            letter-spacing: 0px;
            color: var(--primary);
            margin: 0;
          }
        }
      }

      @media (max-width: 450px) {
        .incentive .incentive__content__body[data-experiment-id='TREE'] {
          flex-wrap: wrap;
        }
      }

      @container incentive (max-width: 450px) {
        .incentive .incentive__content__body[data-experiment-id='TREE'] {
          flex-wrap: wrap;
        }
      }
    `,
  ],
})
export class KendoIncentiveField extends FieldType<FieldTypeConfig> implements OnInit {
  public experiment$!: Observable<Experiment | undefined>;
  public required$!: Observable<boolean>;

  constructor(private backend: BackendService) {
    super();
  }

  ngOnInit(): void {
    const experimentId = this.field.formControl.value;
    this.experiment$ = experimentId ? this.backend.getExperimentById(experimentId) : EMPTY;
  }
}
