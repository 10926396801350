import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ErrorComponent } from './error/error.component';
import { SharedModule } from '@shared/shared.module';
import { AlreadySentComponent } from './already-sent/already-sent.component';
import { InvalidLinkComponent } from './invalid-link/invalid-link.component';
import { ExpiredLinkComponent } from './expired-link/expired-link.component';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';

const COMPONENTS = [
  AlreadySentComponent,
  NotFoundComponent,
  NotAuthorizedComponent,
  ErrorComponent,
  InvalidLinkComponent,
  ExpiredLinkComponent,
  DataPrivacyComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, LayoutModule, TranslateModule.forChild(), SharedModule],
  exports: [...COMPONENTS],
})
export class ViewsModule {}
