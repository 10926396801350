import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/app-routes';
import { APIEndpoints } from '@app/backend/api-endpoints';
import { OrganizationStateService } from '@app/states/organization/organization-state.service';
import { DEFAULT_ORG, Organization } from '@app/states/organization/organization.model';
import { catchError, Observable, switchMap, take, tap, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { BYPASS_AUTH_FLAG } from '@app/backend/backend.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private currentOrg!: Organization | undefined;

  constructor(
    private auth: AuthService,
    private router: Router,
    private state: OrganizationStateService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(BYPASS_AUTH_FLAG) === true) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Basic ${this.auth.getToken()}`,
      },
    });

    return this.state.organization$.pipe(
      take(1),
      tap((organization) => {
        this.currentOrg = organization;
      }),
      switchMap(() => next.handle(request)),
      catchError((error: HttpErrorResponse) => {
        const orgId = this.currentOrg?.id ?? DEFAULT_ORG.id;

        if (
          (error.status === HttpStatusCode.Unauthorized || error.status === HttpStatusCode.Forbidden) &&
          !this.isVerificationError(request)
        ) {
          this.router.navigate([orgId, AppRoutes.Error]);
        }

        return throwError(() => error);
      }),
    );
  }

  private isVerificationError(request: HttpRequest<unknown>) {
    return request.url.includes(APIEndpoints.Auth);
  }
}
