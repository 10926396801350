import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function phoneValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  const validPhoneNumber = /^\+?[0-9]\d{8,15}$/.test(control.value);
  return validPhoneNumber ? null : { phone: true };
}
