import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { Organization } from './organization.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationStateService {
  private state$ = new BehaviorSubject<Organization | undefined>(undefined);
  private lastValue: Organization | undefined;

  public get organization$(): Observable<Organization | undefined> {
    return this.state$.asObservable().pipe(shareReplay(1));
  }

  public get value() {
    return this.lastValue;
  }

  public setOrganization(organization: Organization) {
    this.lastValue = {
      ...organization,
    };
    this.state$.next(organization);
  }
}
