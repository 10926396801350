import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CONTRACT_NUMBER_REGEX, DAMAGE_NUMBER_REGEX } from '@app/states/case/fast-response/fast-response-helper';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function fastResponseValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  const isDamageNumber = DAMAGE_NUMBER_REGEX.test(control.value);
  const isContractNumber = CONTRACT_NUMBER_REGEX.test(control.value);

  const result = isDamageNumber || isContractNumber ? null : { fast_response: true };

  return result;
}
