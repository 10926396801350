import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ValidatorOption } from '@ngx-formly/core/lib/models';
import { isValidIBAN } from 'ibantools';

type SepaValidatiorOptions = {
  allowedCountryCodes: string[];
};

export function sepaValidator(
  control: AbstractControl,
  field: FormlyFieldConfig,
  options: ValidatorOption['options'],
): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  const ibanNumber = control.value as string;
  const countryCode = ibanNumber.slice(0, 2);
  const validCountry =
    (options as SepaValidatiorOptions).allowedCountryCodes.length > 0 &&
    (options as SepaValidatiorOptions).allowedCountryCodes.some(
      (allowedCountryCode) => allowedCountryCode.toLowerCase() == countryCode.toLowerCase(),
    );
  const validIBAN = validCountry && isValidIBAN(ibanNumber);
  return validIBAN ? null : { sepa: true };
}
