import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AppIds, AppRoutes } from '@app/app-routes';
import { BackendService } from '@app/backend/backend.service';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { OrganizationStateService } from './organization-state.service';
import { DEFAULT_ORG, Organization } from './organization.model';
import { EtrackerService } from '@app/etracker/etracker.service';
import { FAST_RESPONSE_IDS } from '../case/fast-response/fast-response-helper';

@Injectable({ providedIn: 'root' })
export class OrganizationResolver implements Resolve<Organization> {
  constructor(
    private title: Title,
    private router: Router,
    private backend: BackendService,
    private organizationStateService: OrganizationStateService,
    private eTracker: EtrackerService,
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Organization> {
    const configId = route.paramMap.get(AppIds.ConfigId);
    const organization = this.organizationStateService.value;

    if (organization) {
      return of(organization);
    }

    return this.backend.getOrganizationById(configId).pipe(
      tap((organization: Organization) => {
        this.updateOrganizationInfos(organization);
      }),
      catchError(() => {
        this.updateOrganizationInfos(DEFAULT_ORG);

        if (configId !== DEFAULT_ORG.id) {
          this.router.navigate([configId, AppRoutes.Error]);
        }

        return of(DEFAULT_ORG);
      }),
    );
  }

  private updateOrganizationInfos(organization: Organization) {
    this.title.setTitle(organization.name);
    this.organizationStateService.setOrganization(organization);
    this.setFaviconByOrganization(organization);
    this.updateTheme(organization);

    if (organization.eTrackerEnabled) {
      this.eTracker.init();
    }
  }

  private setFaviconByOrganization(organization: Organization) {
    if (organization.faviconUrl) {
      const linkElement = document.querySelector('#favicon') as HTMLLinkElement;
      if (linkElement) {
        linkElement.href = organization.faviconUrl;
      }
    }
  }

  private updateTheme(organization: Organization) {
    document.body.setAttribute('theme', organization.frontendTheme);

    /* EXPERIMENTAL START */
    if (FAST_RESPONSE_IDS.includes(organization.id)) {
      document.body.classList.add('experimental-layout');
    }
    /* EXPERIMENTAL END */
  }
}
