<div class="container">
  <ng-container *ngIf="hasWelcomeText">
    <div *ngIf="organization$ | async as organization" class="text centering">
      <div data-cy="welcome-text" [safeHTML]="organization.welcomeText | parsePlaceholders: organization"></div>
    </div>
  </ng-container>

  <div class="centering">
    <form class="k-form" [formGroup]="form" *ngIf="fields$ | async as fields" (ngSubmit)="submit(model)">
      <formly-form
        [form]="form"
        [fields]="fields"
        [options]="options"
        [model]="model"
        (modelChange)="onFormModelChanged($event)"
      ></formly-form>
    </form>
  </div>
</div>
