import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'kendo-form-field-wrapper',
  template: `
    <div class="form-field">
      <kendo-label
        class="form-field__label"
        [ngClass]="props.disabled ? 'form-field__label--disabled' : ''"
        *ngIf="props.label && props.hideLabel !== true"
        [for]="id"
        [text]="props.label"
      >
        <kendo-label
          *ngIf="props.required && props.hideRequiredMarker !== true"
          aria-hidden="true"
          class="form-field__marker"
          text="({{ 'FORM.MARKER.REQUIRED' | translate }})"
        ></kendo-label>
        <kendo-label
          *ngIf="props.optional && props.hideOptionalMarker !== true"
          aria-hidden="true"
          class="form-field__marker"
          text="({{ 'FORM.MARKER.OPTIONAL' | translate }})"
        ></kendo-label>
      </kendo-label>
      <ng-container #fieldComponent></ng-container>
      <kendo-formhint *ngIf="props.description" [ngClass]="props.descriptionClass">
        <div [safeHTML]="props.description | translate"></div>
      </kendo-formhint>

      <kendo-formerror *ngIf="showError">
        <formly-validation-message [field]="field"> </formly-validation-message>
      </kendo-formerror>
    </div>
  `,
  styles: [
    `
      .form-field {
        padding-bottom: 0.5rem;
      }

      .form-field__label {
        padding-bottom: 4px;
        font-weight: bold;
      }

      .form-field__label--disabled {
        ::ng-deep {
          label {
            color: #767676;
            opacity: 0.5;
          }
        }
      }

      .form-field__marker {
        padding-left: 0.25rem;
      }

      .form-field__marker,
      .form-field__label {
        display: inline;
        ::ng-deep > label {
          display: inline;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoFormFieldWrapper extends FieldWrapper {}
