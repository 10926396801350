import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'anchor-field',
  template: `
    <div [ngClass]="props.textClass">
      <a
        [href]="props.pdfUrl"
        target="_blank"
        [ngClass]="{ disabled: !props.pdfUrl }"
        [attr.data-cy]="props['data-cy'] ?? 'anchor-field'"
      >
        <i class="ruv-icon ruv-icon-tiny ruv-download"></i>
        {{ props.text | translate }}
      </a>
    </div>
  `,
  styles: [
    `
      .disabled {
        pointer-events: none;
        color: var(--kendo-button-solid-secondary-disabled-text) !important;
      }
    `,
  ],
})
export class AnchorField extends FieldType<FieldTypeConfig> {}
