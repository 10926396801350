import { DefaultCase } from '../default/default-case.model';

export interface KKinkCase extends DefaultCase {
  externalReference: string; // Versicherungsnummer
  datasetLastAccessedAt: string;
  workshopServiceOptionActive: boolean;
  workshopServiceOptionOffered: boolean;
  isGlassOptionOffered: boolean;
  isCustomerRecommendationOffered: boolean;
  basePrice: number; // <= kh.price + vsv.price + fs.price
  paymentCycle: KKinkPaymentCycle;
  currentOption: KKinkOption;
  recommendedOption: KKinkOption;
  options: KKinkOption[];
  kh: KKinkItem; // Haftpflicht
  vsv: KKinkItem; // Schutzbrief
  fs: KKinkItem; // Fahrerschutz
  licensePlate: string;
  ag: string;
  fid: string;
  selectedOptionId: string;
  frontendOnlyFields: { [key: string]: any };
}

export interface KKinkOption {
  id: string;
  description: string;
  price: number;
  workshopPrice: number;
  isSwitchOption: boolean;
}

export interface KKinkItem {
  description: string | null;
  price: number;
}

export enum KKinkPaymentCycle {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  BIANNUALLY = 'BIANNUALLY',
  YEARLY = 'YEARLY',
}
