import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import get from 'lodash.get';

const TRANSLATE_FIELDS = ['label', 'placeholder', 'text', 'alternateText', 'description', 'content'];

export class TranslateExtension implements FormlyExtension {
  constructor(private translate: TranslateService) {}
  prePopulate(field: FormlyFieldConfig) {
    const props = field.props ?? {};
    if (!props.translate || props.translated) {
      return;
    }

    props.translated = true;
    for (let translateField of TRANSLATE_FIELDS) {
      const templateField = props[translateField];
      if (templateField) {
        const templateKey = `props.${translateField}`;

        if (props.translatedParams) {
          field.expressions = {
            ...(field.expressions ?? {}),
            [templateKey]: () =>
              this.translate.instant(
                templateField,
                this.transformTranslationParams(
                  props.translatedParams,
                  (key, value) => get(field.model, value) ?? get(field.options?.formState.data, value) ?? '',
                ),
              ),
          };
        } else {
          field.expressions = {
            ...(field.expressions ?? {}),
            [templateKey]: () => this.translate.instant(templateField),
          };
        }
      }
    }

    if (Array.isArray(props.options)) {
      const options = props.options;
      props.options = this.translate.stream(options.map((o) => o.label)).pipe(
        map((labels) => {
          return options.map((o) => ({ ...o, label: labels[o.label] }));
        }),
      );
    }
  }

  private transformTranslationParams(
    variables: { [key: string]: string },
    transformFn: (key: string, value: any) => any,
  ) {
    const params = Object.fromEntries(Object.entries(variables).map(([key, value]) => [key, transformFn(key, value)]));
    return params;
  }
}

export function TranslateExtensionFactory(translate: TranslateService) {
  return {
    validationMessages: [
      {
        name: 'required',
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream('FORM.VALIDATION.REQUIRED');
        },
      },
      {
        name: 'email',
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream('FORM.VALIDATION.EMAIL');
        },
      },
      {
        name: 'emailMaxLength',
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream('FORM.VALIDATION.EMAIL_MAX_LENGTH');
        },
      },
      {
        name: 'phone',
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream('FORM.VALIDATION.PHONE');
        },
      },
      {
        name: 'iban',
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream('FORM.VALIDATION.IBAN');
        },
      },
      {
        name: 'ibanBlzForbidden',
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream('FORM.VALIDATION.IBAN_BLZ_FORBIDDEN');
        },
      },
      {
        name: 'sepa',
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream('FORM.VALIDATION.SEPA');
        },
      },
      {
        name: 'min',
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream('FORM.VALIDATION.MIN', {
            min: field.props?.min,
          });
        },
      },
      {
        name: 'max',
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream('FORM.VALIDATION.MAX', {
            max: field.props?.max,
          });
        },
      },
      {
        name: 'code',
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream('FORM.VALIDATION.CODE');
        },
      },
      {
        name: 'specialCase',
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream('FORM.VALIDATION.SPECIAL_CASE');
        },
      },
      {
        name: 'alphaNumeric',
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream('FORM.VALIDATION.ALPHA_NUMERIC');
        },
      },
      {
        name: 'fast_response',
        message(error: any, field: FormlyFieldConfig) {
          return translate.stream('FORM.VALIDATION.FAST_RESPONSE');
        },
      },
      {
        name: 'verification',
        message(error: any, field: FormlyFieldConfig) {
          if (field.model.verificationAttemptsLeft === 1) {
            return translate.stream('FORM.VALIDATION.VERIFICATION.SINGULAR');
          }
          return translate.stream('FORM.VALIDATION.VERIFICATION.PLURAL', {
            attemptsLeft: field.model.verificationAttemptsLeft,
          });
        },
      },
    ],
    extensions: [
      {
        name: 'translate',
        extension: new TranslateExtension(translate),
      },
    ],
  };
}
