import { NgModule } from '@angular/core';
import { LetDirective } from './let.directive';
import { SafeHtmlDirective } from './safeHTML.directive';

@NgModule({
  declarations: [LetDirective, SafeHtmlDirective],
  imports: [],
  exports: [LetDirective, SafeHtmlDirective],
})
export class DirectiveModule {}
