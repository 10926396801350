import { CurrencyPipe } from '@angular/common';
import { DefaultCaseHelper } from '../default/default-case-helper';
import { DefaultCase } from '../default/default-case.model';
import { FormlyFieldConfig } from '@ngx-formly/core';

export class GymCaseHelper extends DefaultCaseHelper<DefaultCase> {
  public formatCurrency(formState: any, model: DefaultCase, field?: FormlyFieldConfig): string {
    const currencyPipe = new CurrencyPipe('de-DE');
    const formattedPrice = currencyPipe.transform(formState.initialModel.totalCredit, undefined, '', '1.2-2', 'de-DE');
    if (!(field?.props?.text ?? '').startsWith('FORM.')) {
      return field?.props?.text ?? '';
    }

    return formState.translateService.instant(field?.props?.text ?? '', {
      totalCredit: formattedPrice,
    });
  }
}
