import { Case } from './case.model';

export class CaseHelper<T extends Case> {
  public preProcessCase(caseObject: T): T {
    return caseObject;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public postProcessCase(caseObject: T, initialCase?: T): any {
    return caseObject;
  }
}
