import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { PresetOption } from '@ngx-formly/core/lib/models';

@Injectable()
export class RtpCompletedPresetProvider {
  constructor() {}
  getConfiguration(): FormlyFieldConfig {
    return {
      type: 'group',
      fieldGroup: [
        {
          type: 'group',
          props: {
            label: 'SUCCESS.RTP_ONBOARDING.HEADER.TEXT',
            labelClass: 'col-12 welcome',
            labelPosition: 'left',
            translate: true,
          },
          fieldGroupClassName: 'grid no-padding',
          fieldGroup: [
            {
              type: 'output',
              className: 'col-12',
              props: {
                textClass: 'text text--bold',
                text: 'SUCCESS.RTP_ONBOARDING.SUBHEADER.TEXT',
                textAlign: 'center',
                translate: true,
                translatedParams: {},
              },
              expressions: {
                'props.translatedParams.salutation': 'field.parent.parent.props.salutation',
                'props.translatedParams.title': 'field.parent.parent.props.title',
                'props.translatedParams.lastName': 'field.parent.parent.props.lastName',
              },
            },
            {
              type: 'output',
              className: 'col-12',
              props: {
                textClass: 'text',
                text: 'SUCCESS.RTP_ONBOARDING.DESCRIPTION.TEXT',
                textAlign: 'left',
                translate: true,
              },
            },
          ],
        },
        {
          type: '#rtp-accordion',
          props: {
            accordionItems: [
              {
                title: 'SUCCESS.RTP_ONBOARDING.FAQ.Q1.QUESTION',
                text: 'SUCCESS.RTP_ONBOARDING.FAQ.Q1.ANSWER',
              },
              {
                title: 'SUCCESS.RTP_ONBOARDING.FAQ.Q2.QUESTION',
                text: 'SUCCESS.RTP_ONBOARDING.FAQ.Q2.ANSWER',
              },
              {
                title: 'SUCCESS.RTP_ONBOARDING.FAQ.Q3.QUESTION',
                text: 'SUCCESS.RTP_ONBOARDING.FAQ.Q3.ANSWER',
              },
              {
                title: 'SUCCESS.RTP_ONBOARDING.FAQ.Q4.QUESTION',
                text: 'SUCCESS.RTP_ONBOARDING.FAQ.Q4.ANSWER',
              },
            ],
          },
        },
      ],
    };
  }
}

export function registerRtpCompletedPreset(): PresetOption {
  return {
    name: 'rtp-completed',
    config: new RtpCompletedPresetProvider(),
  };
}
