import { FastResponseCase, FastResponseContactUpdate } from './fast-response.model';
import { CaseHelper } from '../case-helper';

export const FAST_RESPONSE_IDS = ['23bc1', 'ee143', '9d95d', '72790', '5cd48', 'bb037'];
export const CONTRACT_NUMBER_REGEX = /^(?:\d{9}|\d{11})$/;
export const DAMAGE_NUMBER_REGEX = /^(?:\w{14})$/;

export class FastResponseHelper extends CaseHelper<FastResponseCase> {
  public override preProcessCase(caseObject: FastResponseCase): FastResponseCase {
    caseObject.customData = {
      contractOrDamageNumber: caseObject.customData.contractOrDamageNumber ?? '',
    };

    return caseObject;
  }

  public override postProcessCase(caseObject: FastResponseCase): FastResponseContactUpdate {
    const contractOrDamageNumber = caseObject.customData.contractOrDamageNumber;
    const isDamageNumber = DAMAGE_NUMBER_REGEX.test(contractOrDamageNumber);
    const isContractNumber = CONTRACT_NUMBER_REGEX.test(contractOrDamageNumber);

    return {
      reference: caseObject.reference,
      verificationCode: caseObject.verificationCode,
      ...(isContractNumber && {
        contractNumber: contractOrDamageNumber,
      }),
      ...(isDamageNumber && {
        damageNumber: contractOrDamageNumber,
      }),
    };
  }
}
