import { CurrencyPipe } from '@angular/common';
import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kendo-output-field',
  template: `
    <p [ngClass]="props.textClass" [style.textAlign]="props.textAlign || 'justify'" [safeHTML]="formattedPrice"></p>
  `,
  styles: [],
})
export class KendoPriceOutputField extends FieldType<FieldTypeConfig> {
  constructor(private currencyPipe: CurrencyPipe) {
    super();
  }

  public get formattedPrice(): string {
    const formattedPrice = this.currencyPipe.transform(this.props.text, 'EUR', 'code', '1.2-2', 'de-DE');

    return formattedPrice ?? '- EUR';
  }
}
