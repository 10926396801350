export enum AppRoutes {
  Default = 'default',
  Case = 'case',
  Verification = 'verify',
  Success = 'success',
  NotFound = 'not-found',
  Error = 'error',
  NotAuthorized = 'not-authorized',
  AlreadySent = 'already-sent',
  Doi = 'doubleOptIn',
  InvalidLink = 'invalid-link',
  ExpiredLink = 'expired-link',
  DataPrivacy = 'datenschutz',
}

export enum AppIds {
  JourneyId = 'journeyId',
  ConfigId = 'configId',
}

export enum AppParams {
  Reminder = 'reminder',
  LongCode = 'id',
}
