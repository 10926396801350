import { Component } from '@angular/core';
import { EtrackerService } from '@app/etracker/etracker.service';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kendo-submit-button-field',
  template: `
    <div class="submit-action k-form-buttons">
      <button
        kendoButton
        type="submit"
        themeColor="secondary"
        rounded="large"
        [disabled]="!form.valid"
        (click)="click()"
        [attr.data-cy]="props['data-cy'] ?? 'submit-button'"
      >
        {{ props.text | translate }}
      </button>
    </div>
  `,
  styles: [
    `
      .submit-action {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.5rem;

        button {
          font-weight: bold;
          height: 3rem;
          min-width: 100px;
        }
      }
    `,
  ],
})
export class KendoSubmitButtonField extends FieldType<FieldTypeConfig> {
  constructor(private eTracker: EtrackerService) {
    super();
  }

  public click() {
    this.eTracker.sendClickEvent('Senden', 'send');
  }
}
