import { Component } from '@angular/core';
import { EtrackerService } from '@app/etracker/etracker.service';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'button-field',
  template: `
    <button
      kendoButton
      type="button"
      themeColor="secondary"
      rounded="large"
      (click)="click()"
      [attr.data-cy]="props['data-cy'] ?? 'button-field'"
    >
      {{ props.text | translate }}
    </button>
  `,
  styles: [
    `
      button {
        font-weight: bold;
        height: 3rem;
        min-width: 100px;
      }
    `,
  ],
})
export class ButtonField extends FieldType<FieldTypeConfig> {
  public click() {
    if (!this.props.clickFn) {
      return;
    }
    this.props.clickFn();
  }
}
